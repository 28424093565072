import apiClient from "./base";

interface Booking {
    id_prenotazione?: number;
    id_disponibilita?: number;
    id_medico?: number;
    id_paziente?: number;
    id_richiesta?: number;
    data_prenotazione?: string;
    fascia_oraria_inizio?: string;
    fascia_oraria_fine?: string;
    rifiutato?: boolean;
    cancellato?: boolean;
    telefono?: string;
}

export const decodeBookingToken = async (token: string) => {
    const response = await apiClient.post(`/bookings/decodeBookingToken`, { token });
    return response.data;
}

export const getAvailableDates = async (params?: Booking) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.get(`/bookings/getAvailableDates`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    });
    return response.data;
}

export const createBooking = async (params?: Booking) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.post(`/bookings/createBooking`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const getBookings = async (params?: Booking) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.get(`/bookings/getBookings`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    });
    return response.data;
}

export const rejectBooking = async (params?: Booking) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.post(`/bookings/rejectBooking`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const sendMessage = async (params?: Booking) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.post(`/bookings/createBookingToken`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const createBookingToken = async (params?: Booking) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.post(`/bookings/createBookingToken`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}