import React, { useState, useEffect } from 'react';
import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Fab, TextField, Menu, MenuItem, ListItemIcon, ListItemText, Chip } from '@mui/material';
import { useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useUserProvider } from '../../../providers/useUserProvider';
import SortableTable, { HeadCell } from '../../../components/SortableTable';
import MaterialIcon from '../../../components/MaterialIcon';
import { useSwallowLoading } from '../../../providers/useSwallowLoading';
import { getQuestions, deleteQuestion } from '../../../api/questionnaire';
import DialogQuestionCreate from '../../../components/DialogQuestionCreate';

const QuestionnaireManagement: React.FC = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { user } = useUserProvider();
    const [questions, setQuestions] = useState<any>([]);
    const [searchText, setSearchText] = useState<string>('');

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState<any>({});
    const [dialogMode, setDialogMode] = useState<'create' | 'edit'>('create');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedQuestion, setSelectedQuestion] = useState<any>(null);

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleDialogOpen = () => {
        setDialogOpen(!dialogOpen);
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, question: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedQuestion(question);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedQuestion(null);
    };

    const handleEditQuestion = () => {
        setDialogData(selectedQuestion);
        setDialogMode('edit');
        handleDialogOpen();
        handleMenuClose();
    };

    const handleDeleteQuestion = async () => {
        if (!selectedQuestion) return;

        try {
            await deleteQuestion({ id_domanda: selectedQuestion.id_domanda });
            enqueueSnackbar('Domanda eliminata con successo', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Errore durante l\'eliminazione della domanda', { variant: 'error' });
        } finally {
            handleMenuClose();
        }
    };

    const loadQuestions = new Promise(async (resolve, reject) => {
        try {
            const response = await getQuestions();
            resolve(response);
        } catch (error) {
            enqueueSnackbar('Errore durante il caricamento delle domande', { variant: 'error' });
            reject(error);
        }
    });

    useEffect(() => {
        if (!user) {
            return;
        }

        openLoadingDialog();
        Promise.all([
            loadQuestions
        ])
            .then(([questions]) => {
                setQuestions(questions);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                closeLoadingDialog();
            });
    }, []);

    const columns: HeadCell<any>[] = user.userType === 1 ? [
        { id: 'id', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'question', numeric: false, disablePadding: false, label: 'Testo Domanda' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
    ] : [
        { id: 'id', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'question', numeric: false, disablePadding: false, label: 'Testo Domanda' },
    ];

    // Renderizza ogni riga
    const renderRow = (question: any) => (
        <TableRow key={question.id_domanda}>
            <TableCell align='right'>{question.id_domanda}</TableCell>
            <TableCell>{question.testo_domanda}</TableCell>
            {user.userType === 1 && (
                <TableCell>
                    <IconButton onClick={(event) => handleMenuOpen(event, question)}>
                        <MaterialIcon icon="more_vert" />
                    </IconButton>
                </TableCell>
            )}
        </TableRow>
    );

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Gestione Questionario</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Gestisci le domande del questionario
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                {user.userType === 1 && (
                    <Fab color="success" aria-label="add" onClick={handleDialogOpen}>
                        <MaterialIcon icon="add" color={theme.palette.textDark.primary} />
                    </Fab>
                )}
                <TextField
                    label="Cerca domande"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    rows={questions}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <DialogQuestionCreate open={dialogOpen} onClose={handleDialogOpen} initialData={dialogData} mode={dialogMode} onUpdate={() => null} />
            {user.userType === 1 && (
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}>
                    <MenuItem onClick={handleEditQuestion}>
                        <ListItemIcon>
                            <MaterialIcon icon="edit" />
                        </ListItemIcon>
                        <ListItemText>Modifica domanda</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleDeleteQuestion}>
                        <ListItemIcon>
                            <MaterialIcon icon="delete" />
                        </ListItemIcon>
                        <ListItemText>Elimina domanda</ListItemText>
                    </MenuItem>
                </Menu>
            )}
        </Box>
    );
};

export default QuestionnaireManagement;
