import React, { useState, useEffect } from 'react';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, Divider, FilledInput, FormControl, FormControlLabel, Grid, List, ListItem, ListItemText, OutlinedInput, Radio, RadioGroup, Tab, Tabs, Typography, useTheme, CircularProgress, ListSubheader, Chip } from "@mui/material";
import { useSnackbar } from "notistack";
import { getObjectiveExamination, getRequestDocument, getRequests } from "../api/requests";
import { getMedicalHistory, getUsers } from "../api/users";
import { useUserProvider } from "../providers/useUserProvider";
import { getQuestionnaire, compileQuestionnaire } from "../api/questionnaire";
import { medicalHistoryKeys } from "../common/utils";
import { generateReport } from '../api/reports';
import { useSwallowLoading } from "../providers/useSwallowLoading";

const PatientCard = ({ patient, medicalHistory, objectiveExamination }: { patient: any, medicalHistory: any, objectiveExamination: any }) => {
    const theme = useTheme();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const renderDetails = () => (
        <Grid container columnSpacing="1rem">
            <Grid item xs={6}>
                <ListItem>
                    <ListItemText primary="Nome" secondary={patient?.nome} />
                </ListItem>
            </Grid>
            <Grid item xs={6}>
                <ListItem>
                    <ListItemText primary="Cognome" secondary={patient?.cognome} />
                </ListItem>
            </Grid>
            <Grid item xs={6}>
                <ListItem>
                    <ListItemText primary="Codice Fiscale" secondary={patient?.codice_fiscale} />
                </ListItem>
            </Grid>
            <Grid item xs={6}>
                <ListItem>
                    <ListItemText primary="Data di nascita" secondary={patient.data_nascita && (new Date(patient.data_nascita)).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' })} />
                </ListItem>
            </Grid>
            <Grid item xs={6}>
                <ListItem>
                    <ListItemText primary="E-mail" secondary={patient?.email} />
                </ListItem>
            </Grid>
            <Grid item xs={6}>
                <ListItem>
                    <ListItemText primary="Telefono" secondary={patient?.telefono} />
                </ListItem>
            </Grid>
        </Grid>
    );

    const renderMedicalHistory = () => {
        if (!medicalHistory || Object.keys(medicalHistory).length === 0) {
            return (
                <ListItem>
                    <ListItemText primary="Nessuna anamnesi disponibile" />
                </ListItem>
            );
        }

        // Group the medical history by type: we have to find first the type by using the key of the anamnesi object
        const groupedMedicalHistory: { [key: string]: any } = {};
        Object.keys(medicalHistory).forEach(key => {
            const type = medicalHistoryKeys[key as keyof typeof medicalHistoryKeys].type;
            if (!groupedMedicalHistory[type]) {
                groupedMedicalHistory[type] = {};
            }
            groupedMedicalHistory[type][key] = medicalHistory[key];
        });

        return (
            <>
                <Box>
                    {Object.keys(groupedMedicalHistory).map((type, index) => (
                        <>
                            <ListSubheader key={index} sx={{ bgcolor: "#F5F5F5" }}>{type}</ListSubheader>
                            <Grid key={index} container>
                                {Object.keys(groupedMedicalHistory[type]).map((key) => (
                                    <Grid key={key} xs={12} md={6}>
                                        <ListItem>
                                            <ListItemText primary={medicalHistoryKeys[key as keyof typeof medicalHistoryKeys].name} secondary={groupedMedicalHistory[type][key]} />
                                        </ListItem>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    ))}
                </Box>
            </>
        );
    };

    const renderObjectiveExamination = () => {
        if (!objectiveExamination || objectiveExamination.length === 0) {
            return (
                <ListItem>
                    <ListItemText primary="Nessun esame obiettivo disponibile" />
                </ListItem>
            );
        }

        return (
            <List>
                {objectiveExamination && objectiveExamination.map((item: any, index: number) => (
                    <ListItem key={index}>
                        <ListItemText primary={item.domanda} secondary={item.risposta} />
                    </ListItem>
                ))}
            </List>
        )
    };

    return (
        <Card sx={{ borderRadius: "1rem", padding: "0rem", marginBottom: "1rem" }}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Tabs"
                variant='fullWidth'>
                <Tab label="Dettagli" />
                <Tab label="Anamnesi" />
                <Tab label="Esame obiettivo" />
            </Tabs>
            <Divider />
            {tabValue === 0 && renderDetails()}
            {tabValue === 1 && renderMedicalHistory()}
            {tabValue === 2 && renderObjectiveExamination()}
        </Card>
    );
}

const ReportCard = ({ reportText, document }: { reportText: string, document: any }) => {
    const theme = useTheme();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    }

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    }

    return (
        <Card sx={{ borderRadius: "1rem", padding: "2rem" }}>
            <Typography variant="subtitle1" color={theme.palette.text.primary} fontWeight="bold">Referto Cardiologico</Typography>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
            <Typography variant="body1" color={theme.palette.text.primary}>{reportText ?? 'Referto non ancora disponibile'}</Typography>
            {document && (
                <>
                    <Button variant="contained" color="primary" onClick={handleDialogOpen} sx={{ marginTop: "2rem" }}>Visualizza ECG</Button>
                    <Dialog
                        open={isDialogOpen}
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "auto",
                                    maxWidth: "100%",
                                },
                            },
                        }}>
                        <DialogContent sx={{ overflow: "scroll" }}>
                            {document ? (
                                <img src={document} alt="Elettrocardiogramma" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                            ) : (
                                <Typography variant="body1">Documento non disponibile</Typography>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose}>Chiudi</Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Card>
    );
}

const QuestionnaireCard = ({ questions, onChange, editable }: { questions: any, onChange: any, editable: boolean }) => {
    const [answers, setAnswers] = useState<any>({});
    const theme = useTheme();

    useEffect(() => {
        const initialAnswers: any = {};
        questions.forEach((question: any) => {
            if (question.risposta !== 'Si' && question.risposta !== 'No') {
                initialAnswers[question.id_domanda] = { option: 'Altro', text: question.risposta };
            } else {
                initialAnswers[question.id_domanda] = { option: question.risposta, text: '' };
            }
        });
        setAnswers(initialAnswers);
    }, [questions]);

    const handleOptionChange = (questionId: number, option: string, text: string = '') => {
        const newAnswers = {
            ...answers,
            [questionId]: { option, text }
        };
        setAnswers(newAnswers);
        onChange(newAnswers);
    };

    return (
        <Card sx={{ borderRadius: "1rem", padding: "2rem" }}>
            <Typography variant="subtitle1" color={theme.palette.text.primary} fontWeight="bold">Questionario Anamnestico</Typography>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
            {questions && questions.map((question: any) => (
                <Box key={question.id_domanda} marginTop="1rem">
                    <Typography variant="body2" gutterBottom>{question.testo_domanda}</Typography>
                    <RadioGroup
                        name={`question-${question.id_domanda}`}
                        value={answers[question.id_domanda]?.option || ''}
                        onChange={(e) => handleOptionChange(question.id_domanda, e.target.value)}>
                        <FormControlLabel value="Si" control={<Radio disabled={!editable} />} label="Sì" />
                        <FormControlLabel value="No" control={<Radio disabled={!editable} />} label="No" />
                        <FormControlLabel value="Altro" control={<Radio disabled={!editable} />} label="Altro" />
                    </RadioGroup>
                    {answers[question.id_domanda]?.option === 'Altro' && (
                        <OutlinedInput
                            sx={{ marginTop: ".5rem" }}
                            value={answers[question.id_domanda]?.text || ''}
                            onChange={(e) => handleOptionChange(question.id_domanda, 'Altro', e.target.value)}
                            placeholder="Inserisci la tua risposta"
                            disabled={!editable}
                            fullWidth
                        />
                    )}
                </Box>
            ))}
        </Card>
    );
};

const NotesCard = ({ editable, onSave, onApprove, onReject }: { editable: boolean, onSave: any, onApprove: any, onReject: any }) => {
    const [notes, setNotes] = useState('');
    const theme = useTheme();

    return (
        <Card sx={{ borderRadius: "1rem", padding: "2rem" }}>
            <Typography variant="subtitle1" color={theme.palette.text.primary} fontWeight="bold">Idoneità Sportiva</Typography>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
            <Box>
                <FormControl fullWidth>
                    <FilledInput
                        id="report"
                        multiline
                        rows={4}
                        placeholder="Inserisci qui le note aggiuntive per il referto..."
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        disabled={!editable}
                    />
                </FormControl>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    justifyContent: "end",
                    marginTop: "2rem"
                }}>
                {editable && (
                    <>
                        <Button variant="text" color="primary" sx={{ marginRight: "auto" }} onClick={() => onSave(notes)}>
                            Salva bozza
                        </Button>
                        <Button variant="text" color="primary" onClick={onReject}>
                            Paziente non idoneo
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => onApprove(notes)}>
                            Paziente idoneo
                        </Button>
                    </>
                )}
            </Box>
        </Card>
    );
};

const RequestDetailsLayout = ({ requestId, patientId, onClose }: { requestId?: number, patientId?: number, onClose?: () => void }) => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const { enqueueSnackbar } = useSnackbar();
    const [request, setRequest] = useState<any>();
    const [patient, setPatient] = useState<any>();
    const [questions, setQuestions] = useState<any[]>([]);
    const [answers, setAnswers] = useState<any>({});
    const [medicalHistory, setMedicalHistory] = useState<any>({});
    const [objectiveExamination, setObjectiveExamination] = useState<any[]>([]);
    const [document, setDocument] = useState<any>();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    useEffect(() => {
        if (document) {
            URL.revokeObjectURL(document);
        }

        if (!requestId || !patientId) {
            return;
        }

        const loadDetails = async () => {
            try {
                openLoadingDialog();

                const [requestResponse, patientResponse, medicalHistoryResponse, questionsResponse, objectiveExaminationResponse, documentResponse] = await Promise.all([
                    getRequests({ id_richiesta: requestId }),
                    getUsers({ id_utenti: patientId }),
                    getMedicalHistory({ id_utente: patientId }),
                    getQuestionnaire({ id_richiesta: requestId }),
                    getObjectiveExamination({ id_richiesta: requestId }),
                    getRequestDocument(requestId)
                ]);

                const request = requestResponse[0];
                const patient = patientResponse[0];
                const medicalHistory = medicalHistoryResponse[0];
                const questions = questionsResponse;
                const objectiveExamination = objectiveExaminationResponse[0] ? JSON.parse(objectiveExaminationResponse[0].esame) : [];
                let documentUrl;

                if (documentResponse) {
                    const blob = new Blob([documentResponse], { type: 'image/jpeg' });
                    documentUrl = URL.createObjectURL(blob);
                }

                if (medicalHistory) {
                    Object.keys(medicalHistory).forEach(key => {
                        if (
                            medicalHistory[key] === null
                            || medicalHistory[key] === ""
                            || medicalHistory[key] === "No"
                            || medicalHistory[key] === "no"
                            || medicalHistory[key] === "NO"
                            || medicalHistory[key].length === 0
                            || key === "id_utente" || key === "id_anamnesi" || key === "tempo_creazione") {
                            delete medicalHistory[key];
                        }
                    });
                }

                setRequest(request);
                setPatient(patient);
                setMedicalHistory(medicalHistory ?? {});
                setQuestions(questions);
                setObjectiveExamination(objectiveExamination);
                setDocument(documentUrl);

                // Imposta le risposte salvate nel questionario
                const initialAnswers: any = {};
                questionsResponse.forEach((question: any) => {
                    if (question.risposta !== 'Si' && question.risposta !== 'No') {
                        initialAnswers[question.id_domanda] = { option: 'Altro', text: question.risposta };
                    } else {
                        initialAnswers[question.id_domanda] = { option: question.risposta, text: '' };
                    }
                });
                setAnswers(initialAnswers);
            } catch (error) {
                console.log(error);
                enqueueSnackbar('Errore durante il caricamento dei dettagli', { variant: 'error' });
            } finally {
                closeLoadingDialog();
                setIsPageLoading(false);
            }
        };

        loadDetails();
    }, [requestId, patientId, user.userId, enqueueSnackbar]);

    const handleQuestionnaireChange = (newAnswers: any) => {
        setAnswers(newAnswers);
    };

    const handleSave = async (notes: string) => {
        try {
            openLoadingDialog();
            await compileQuestionnaire({
                id_richiesta: requestId,
                questionario: Object.keys(answers).map(id_domanda => ({
                    id_domanda: parseInt(id_domanda),
                    risposta: answers[id_domanda].option === 'Altro' ? answers[id_domanda].text : answers[id_domanda].option
                }))
            });
            enqueueSnackbar('Bozza salvata con successo', { variant: 'success' });
            if (onClose) onClose();
        } catch (error) {
            enqueueSnackbar('Errore durante il salvataggio della bozza', { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    const handleApprove = async (notes: string) => {
        try {
            openLoadingDialog();
            await compileQuestionnaire({
                id_richiesta: requestId,
                questionario: Object.keys(answers).map(id_domanda => ({
                    id_domanda: parseInt(id_domanda),
                    risposta: answers[id_domanda].option === 'Altro' ? answers[id_domanda].text : answers[id_domanda].option
                }))
            });
            await generateReport({
                id_richiesta: requestId,
                note_aggiuntive: notes,
                idoneo: 1,
            });
            enqueueSnackbar('Richiesta approvata con successo', { variant: 'success' });
            if (onClose) onClose();
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Errore durante l\'approvazione della richiesta', { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    const handleReject = async () => {
        try {
            openLoadingDialog();
            await compileQuestionnaire({
                id_richiesta: requestId,
                questionario: Object.keys(answers).map(id_domanda => ({
                    id_domanda: parseInt(id_domanda),
                    risposta: answers[id_domanda].option === 'Altro' ? answers[id_domanda].text : answers[id_domanda].option
                }))
            });
            await generateReport({
                id_richiesta: requestId,
                idoneo: 0,
            });
            enqueueSnackbar('Richiesta respinta con successo', { variant: 'success' });
            if (onClose) onClose();
        } catch (error) {
            enqueueSnackbar('Errore durante il rifiuto della richiesta', { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    if (!requestId || !patientId || isPageLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    const isRequestComplete = request?.fl_completo_richiesta;
    const isEditable = user.userType === 3; // Solo il medico può modificare

    return (
        <Box>
            <Typography variant="h4" marginBottom="1rem" color={theme.palette.textDark.primary} fontWeight="bold" textTransform="capitalize">Richiesta #{requestId} - {patient?.nome.toLowerCase()} {patient?.cognome.toLowerCase()}</Typography>
            {patient && medicalHistory && objectiveExamination && (
                <PatientCard patient={patient} medicalHistory={medicalHistory} objectiveExamination={objectiveExamination} />
            )}
            {request && questions && (
                <Grid container columnSpacing="1rem" marginBottom="1rem">
                    <Grid item xs={6}>
                        <ReportCard reportText={request?.text_refertazione} document={document} />
                    </Grid>
                    <Grid item xs={6}>
                        <QuestionnaireCard questions={questions} onChange={handleQuestionnaireChange} editable={isEditable && !isRequestComplete} />
                    </Grid>
                </Grid>
            )}
            {isEditable && !isRequestComplete && <NotesCard editable={isEditable} onSave={handleSave} onApprove={handleApprove} onReject={handleReject} />}
        </Box>
    );
};

export default RequestDetailsLayout;
