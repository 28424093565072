import React, { useEffect, useRef } from 'react';
import { Box, Button, Card, FormControl, FormHelperText, IconButton, InputLabel, OutlinedInput, useTheme } from "@mui/material";
import Logo from '../../../components/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserProvider } from '../../../providers/useUserProvider';
import { login } from '../../../api/auth';
import { useSnackbar } from 'notistack';
import MaterialIcon from '../../../components/MaterialIcon';
import { useForm } from 'react-hook-form';
import { FISCAL_CODE_OR_VAT_REGEX, PASSWORD_REGEX } from '../../../config/regex';

type FormValues = {
    username: string;
    password: string;
}

const Login: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState, getValues } = useForm<FormValues>({
        defaultValues: {
            username: "",
            password: ""
        },
    });
    const { errors } = formState;
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    const onSubmit = async () => {
        const username = getValues("username");
        const password = getValues("password");

        if (isRequestInProgress) {
            return;
        }

        try {
            setIsRequestInProgress(true);

            const response = await login({
                codice_fiscale: username,
                password: password,
                devMode: window.location.hostname.includes("localhost")
            });

            navigate('/login/step2', { state: { username: username } });
        } catch (error: any) {
            setIsRequestInProgress(false);
            enqueueSnackbar(error.message, { variant: "error" });
        }
    }

    const handleTogglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    }

    return (
        <Box
            className="LoginPage"
            alignItems="center"
            width="100svw"
            height="100svh"
            display="flex"
            justifyContent="center"
            bgcolor={theme.palette.primary.main}>
            <Card sx={{ maxWidth: "28rem", margin: "1rem", boxSizing: "border-box", width: "100%" }}>
                <Logo style={{ height: "4.5rem", marginBottom: "2.5rem" }} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth variant='outlined' error={!!errors.username}>
                        <InputLabel htmlFor="username">Codice Fiscale o Partita IVA</InputLabel>
                        <OutlinedInput
                            id="username"
                            type="text"
                            fullWidth
                            label="Codice Fiscale o Partita IVA"
                            {...register("username", {
                                required: "Il campo è obbligatorio",
                                pattern: FISCAL_CODE_OR_VAT_REGEX
                            })}
                        />
                        {errors.username && (
                            <FormHelperText>{errors.username.message}</FormHelperText>
                        )}
                    </FormControl>
                    <Box height="1rem" />
                    <FormControl fullWidth variant='outlined' sx={{ marginBottom: "2rem" }} error={!!errors.password}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={isPasswordVisible ? "text" : "password"}
                            fullWidth
                            endAdornment={
                                <IconButton
                                    edge="end"
                                    onClick={handleTogglePasswordVisibility}
                                >
                                    {!isPasswordVisible ? <MaterialIcon icon="visibility" /> : <MaterialIcon icon="visibility_off" />}
                                </IconButton>
                            }
                            label="Password"
                            {...register("password", {
                                required: "Il campo è obbligatorio",
                                pattern: PASSWORD_REGEX
                            })}
                        />
                        {errors.password && (
                            <FormHelperText>{errors.password.message}</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl fullWidth>
                        <Button variant="contained" type="submit">Login</Button>
                    </FormControl>
                </form>
                <Box height="1rem" />
                <FormControl fullWidth>
                    <Button variant="text">Hai dimenticato la password?</Button>
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: "-1rem" }}>
                    <Button variant="text">Crea un nuovo account</Button>
                </FormControl>
            </Card>
        </Box >
    );
}

export default Login;
