import apiClient from "./base";

interface Question {
    id_domanda?: number;
    testo_domanda?: string;
    fl_visibile?: boolean;
}

export const getQuestions = async (params?: Question) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.get("/questionnaire/getQuestions", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: params,
    });
    return response.data;
}

export const addQuestion = async (params: Question) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.post("/questionnaire/createQuestion", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const deleteQuestion = async (params: Question) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.delete(`/questionnaire/deleteQuestion`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: params,
    });
    return response.data;
}

export const updateQuestion = async (params: Question) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.post(`/questionnaire/updateQuestion`, params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

interface Answer {
    id_risposta?: number;
    id_domanda?: number;
    id_richiesta?: number;
}

export const getQuestionnaire = async (params?: Answer) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.get("/questionnaire/getQuestionnaire", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: params,
    });
    return response.data;
}

export const getCompiledQuestionnaire = async (params?: Answer) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.get("/questionnaire/getCompiledQuestionnaire", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: params,
    });
    return response.data;
}

interface Questionnaire {
    id_richiesta?: number;
    questionario?: { id_domanda: number, risposta: string }[];
}

export const compileQuestionnaire = async (params: Questionnaire) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.post("/questionnaire/compileQuestionnaire", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}