import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { addQuestion, updateQuestion } from "../api/questionnaire"; // Update these imports to match your API endpoints

type FormValues = {
    id?: number;
    testo_domanda: string;
};

const DialogQuestionCreate: React.FC<DialogProps & { initialData?: FormValues; mode: 'create' | 'edit'; onUpdate: () => void }> = (props) => {
    const { register, handleSubmit, getValues, setValue, formState, reset } = useForm<FormValues>({
        defaultValues: props.initialData
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        reset(props.initialData);
    }, [props.open, props.initialData, reset]);

    const onSubmit = async () => {
        const data = getValues();

        try {
            if (props.mode === 'create') {
                await addQuestion(data as any);
            } else {
                await updateQuestion(data as any);
            }

            enqueueSnackbar('Domanda salvata con successo', { variant: 'success' });
            props.onUpdate();
            onClose();
        } catch (error) {
            enqueueSnackbar('Errore durante il salvataggio della domanda', { variant: 'error' });
        }
    }

    const onClose = () => {
        props.onClose?.({}, 'escapeKeyDown');
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>{props.mode === 'create' ? 'Nuova Domanda' : 'Modifica Domanda'}</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Compila il form per {props.mode === 'create' ? 'creare' : 'modificare'} una domanda.</DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.testo_domanda}>
                            <InputLabel htmlFor="questionText">Testo Domanda</InputLabel>
                            <OutlinedInput
                                id="questionText"
                                label="Testo Domanda"
                                fullWidth
                                multiline
                                rows={4}
                                {...register("testo_domanda", {
                                    required: "Il campo è obbligatorio",
                                })} />
                            {!!errors.testo_domanda && (
                                <FormHelperText>{errors.testo_domanda.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogQuestionCreate;
