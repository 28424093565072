import React, { useEffect, useState } from 'react';
import { Box, Divider, Drawer, IconButton, Toolbar, styled, useTheme, Button, useMediaQuery, ListItemButton, ListItemText, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import './App.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import MaterialIcon from '../../components/MaterialIcon';
import DrawerList from '../../components/DrawerList';
import { useUserProvider } from '../../providers/useUserProvider';
import Logo from '../../components/Logo';
import useUserActivity from '../../hooks/useUserActivity';
import { getAvailabilitiesCount } from '../../api/availabilities';

const drawerWidth = "18rem";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
  isSmallScreen?: boolean;
}>(({ theme, open, isSmallScreen }) => ({
  flexGrow: 1,
  width: "100%",
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isSmallScreen ? 0 : `-${drawerWidth}`,
  ...(open && {
    width: isSmallScreen ? "100%" : `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  isSmallScreen?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, isSmallScreen }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: isSmallScreen ? "100%" : `calc(100% - ${drawerWidth})`,
    marginLeft: isSmallScreen ? 0 : `${drawerWidth}`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function App() {
  useUserActivity();
  const theme = useTheme();
  const { user } = useUserProvider();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [availabilitiesDialogOpen, setAvailabilitiesDialogOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    navigate("/logout");
  }

  const handleAvailabilitiesDialogOpen = () => {
    setAvailabilitiesDialogOpen(true);
  }

  const handleAvailabilitiesDialogClose = () => {
    setAvailabilitiesDialogOpen(false);
    navigate("/availabilities");
  }

  useEffect(() => {
    const loadAvailabilitiesCount = async () => {
      try {
        const response = await getAvailabilitiesCount({
          id_medico_sport: user.userId!
        });

        if (response.count < 5) {
          handleAvailabilitiesDialogOpen();
        }
      } catch (error: any) {
        console.log(error.message);
      }
    }

    if (user.userType === 3) {
      loadAvailabilitiesCount();
    }

    if (!user.userId || !user.userType || !localStorage.getItem("token")) {
      navigate("/login/step1");
    }
  }, []);

  return (
    <Box
      sx={{
        height: "100svh",
        width: "100svw",
        backgroundColor: theme.palette.background.default
      }}>
      <Box display="flex" width="100%" height="100%">
        <AppBar position="fixed" sx={{ boxShadow: "none" }} open={open} isSmallScreen={isSmallScreen}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MaterialIcon icon="menu" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant={isSmallScreen ? "temporary" : "persistent"}
          anchor="left"
          open={open}
          onClose={toggleDrawer}
        >
          <DrawerHeader>
            <IconButton onClick={toggleDrawer}>
              <MaterialIcon icon={isSmallScreen ? "close" : "chevron_left"} />
            </IconButton>
          </DrawerHeader>
          <Box marginLeft="4rem" marginRight="4rem" marginBottom="4rem">
            <Logo />
          </Box>
          <Divider />
          <Box
            sx={{
              flexGrow: 1,
              overflow: "auto"
            }}>
            <DrawerList toggleDrawer={toggleDrawer} />
          </Box>
          <Divider />
          <ListItem
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}>
            <ListItemButton
              onClick={handleLogout}
              sx={{
                width: "100%",
                borderRadius: "0.5rem",
                backgroundColor: "transparent",
              }}>
              <MaterialIcon icon="logout" color={theme.palette.text.secondary} />
              <ListItemText
                primary="Logout"
                sx={{
                  marginLeft: "1rem",
                  marginRight: "auto",
                  color: theme.palette.text.secondary,
                }} />
            </ListItemButton>
          </ListItem>
        </Drawer>
        <Main open={open} isSmallScreen={isSmallScreen}>
          <DrawerHeader />
          <Outlet />
        </Main>
        <Dialog open={availabilitiesDialogOpen} fullWidth>
          <DialogTitle>
            Attenzione
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" color={theme.palette.text.primary}>
              Hai meno di 5 disponibilità impostate per i prossimi giorni.<br />Cliccando sul pulsante qui sotto verrai reindirizzato alla pagina delle disponibilità per impostarne di nuove, garantendo un servizio migliore.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAvailabilitiesDialogClose}>Chiudi</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default App;
