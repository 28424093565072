import apiClient from "./base";

interface Availabilities {
    id_disponibilita?: number;
    id_medico_sport?: number;
    data_disponibilita?: string;
    orario_mattina_inizio?: string | null;
    orario_mattina_fine?: string | null;
    orario_pomeriggio_inizio?: string | null;
    orario_pomeriggio_fine?: string | null;
}

export const getAvailabilities = async (params: Availabilities) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.get(`/availabilities/getAvailabilities`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    });
    return response.data;
};

export const getAvailabilitiesCount = async (params: Availabilities) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.get(`/availabilities/getAvailabilitiesCount`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    });
    return response.data;
}

export const createAvailability = async (params: Availabilities) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.post(`/availabilities/createAvailability`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const updateAvailability = async (params: Availabilities) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.post(`/availabilities/updateAvailability`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const deleteAvailability = async (params: Availabilities) => {
    const token = localStorage.getItem("token");
    const response = await apiClient.post(`/availabilities/deleteAvailability`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}