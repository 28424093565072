import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyToken } from '../api/auth';
import { AxiosError } from 'axios';

// Definizione del tipo di stato
interface UserState {
    userId: number | null;
    userType: number | null;
}

// Inizializza il context con un valore di default undefined
const UserContext = createContext<{
    user: UserState;
    setUserId: (userId: number) => void;
    setUserType: (userType: number) => void;
    clearUser: () => void;
} | undefined>(undefined);

// Crea un provider
interface UserProviderProps {
    children: ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
    const [user, setUser] = useState<UserState>({
        userId: null,
        userType: null,
    });

    const navigate = useNavigate(); // Aggiunto per la navigazione

    const setUserId = (userId: number) => {
        setUser((prevUser) => ({ ...prevUser, userId }));
    }

    const setUserType = (userType: number) => {
        setUser((prevUser) => ({ ...prevUser, userType }));
    }

    const clearUser = () => {
        setUser({ userId: null, userType: null });
    }

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await verifyToken();
                setUserId(response.id_utenti);
                setUserType(response.tipi_utenti_id_tipi_utenti);
            } catch (error) {
                // Controlla se l'errore è dovuto a un token non valido
                if (error instanceof AxiosError && error.response && error.response.status === 401) {
                    clearUser();
                    localStorage.removeItem('token');
                    navigate('/login/step1');
                } else {
                    // Potresti voler gestire altri tipi di errori qui
                    console.error("Errore durante la verifica del token", error);
                }
            }
            finally {
                setIsLoading(false);
            }
        };

        checkToken();
    }, []);

    return (
        <UserContext.Provider value={{ user, setUserId, setUserType, clearUser }}>
            {!isLoading && children}
        </UserContext.Provider>
    );
}

// Hook personalizzato per utilizzare il context
export function useUserProvider() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserProvider must be used within a UserProvider');
    }
    return context;
}