import React, { useEffect, useRef } from 'react';
import { Box, Button, Card, FormControl, FormHelperText, IconButton, InputLabel, OutlinedInput, Typography, useTheme } from "@mui/material";
import Logo from '../../../components/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserProvider } from '../../../providers/useUserProvider';
import { login, verifyOtp } from '../../../api/auth';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { FISCAL_CODE_OR_VAT_REGEX, PASSWORD_REGEX } from '../../../config/regex';

type FormValues = {
    otp: string;
}

const ValidateOTP: React.FC = () => {
    const { state } = useLocation();
    const { username } = state;

    const { setUserId, setUserType } = useUserProvider();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState, getValues } = useForm<FormValues>({
        defaultValues: {
            otp: "",
        },
    });
    const { errors } = formState;
    const navigate = useNavigate();
    const theme = useTheme();

    const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    const onSubmit = async () => {
        const otp = getValues("otp");

        if (isRequestInProgress) {
            return;
        }

        try {
            setIsRequestInProgress(true);

            const { userId, userType, token } = await verifyOtp(username, otp);

            setUserId(userId);
            setUserType(userType);

            localStorage.setItem("token", token);

            enqueueSnackbar("Login effettuato con successo", { variant: "success" });

            switch (userType) {
                case 1:
                    navigate("/requests", {});
                    break;
                case 3:
                    navigate("/dashboard", {});
                    break;
            }
        } catch (error: any) {
            setIsRequestInProgress(false);
            enqueueSnackbar(error.message, { variant: "error" });
        }
    }

    const handleBackToLogin = () => {
        navigate("/login/step1", {});
    }

    useEffect(() => {
        if (!username) {
            navigate("/login/step1", {});
        }
    }, []);

    return (
        <Box
            className="LoginPage"
            alignItems="center"
            width="100svw"
            height="100svh"
            display="flex"
            justifyContent="center"
            bgcolor={theme.palette.primary.main}>
            <Card sx={{ maxWidth: "28rem", margin: "1rem", boxSizing: "border-box", width: "100%" }}>
                <Logo style={{ height: "4.5rem", marginBottom: "2.5rem" }} />
                <Box textAlign="center">
                    <Typography variant="h5" gutterBottom>Verifica Codice OTP</Typography>
                    <Typography variant="body2">Inserisci il codice OTP a 6 cifre ricevuto tramite SMS</Typography>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth variant='outlined' error={!!errors.otp} sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
                        <InputLabel htmlFor="otp">Codice OTP</InputLabel>
                        <OutlinedInput
                            id="otp"
                            type="text"
                            fullWidth
                            label="Codice OTP"
                            {...register("otp", {
                                required: "Il codice OTP è obbligatorio",
                                pattern: {
                                    value: /^\d{6}$/,
                                    message: "Il codice OTP deve essere composto da 6 cifre"
                                }
                            })}
                        />
                        {errors.otp && (
                            <FormHelperText>{errors.otp.message}</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl fullWidth>
                        <Button variant="contained" type="submit">Verifica</Button>
                    </FormControl>
                </form>
                <Box height="1rem" />
                <FormControl fullWidth>
                    <Button variant="text">Invia un nuovo codice di verifica</Button>
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: "-1rem" }}>
                    <Button variant="text" onClick={handleBackToLogin}>Torna al login</Button>
                </FormControl>
            </Card>
        </Box >
    );
}

export default ValidateOTP;
