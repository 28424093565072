import apiClient from "./base";

interface Login {
    codice_fiscale: string;
    password: string;
    devMode?: boolean;
}

export const login = async (params: Login) => {
    const response = await apiClient.post('/authentication/login', params);
    return response.data;
}

export const logout = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
        throw new Error('Token not found');
    }

    const response = await apiClient.post('/authentication/logout', {}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}

export const verifyOtp = async (codice_fiscale: string, otp: string) => {
    const response = await apiClient.post('/authentication/verifyOtp', { codice_fiscale, otp });
    return response.data;
}

export const verifyToken = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
        throw new Error('Token not found');
    }

    const response = await apiClient.get('/authentication/verifyToken', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}