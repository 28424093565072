import React, { useState, useEffect } from 'react';
import { Box, Card, TableCell, TableRow, Typography, IconButton, TextField, Menu, MenuItem, ListItemIcon, ListItemText, Chip, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useUserProvider } from '../../../../providers/useUserProvider';
import SortableTable, { HeadCell } from '../../../../components/SortableTable';
import MaterialIcon from '../../../../components/MaterialIcon';
import { useSwallowLoading } from '../../../../providers/useSwallowLoading';
import { extractDateOfBirthFromItalianFiscalCode } from '../../../../common/utils';
import { useNavigate } from 'react-router-dom';
import { getRequests } from '../../../../api/requests';
import { createBookingToken, sendMessage } from '../../../../api/bookings';

const RequestsList: React.FC = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const navigate = useNavigate();
    const { user } = useUserProvider();
    const [requests, setRequests] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [requestStatusFilter, setRequestStatusFilter] = useState<string>('1');
    const [bookingStatusFilter, setBookingStatusFilter] = useState<string>('');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRequest, setSelectedRequest] = useState<any>(null);

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleRequestStatusFilterChange = (event: SelectChangeEvent) => {
        setRequestStatusFilter(event.target.value);
    };

    const handleBookingStatusFilterChange = (event: SelectChangeEvent) => {
        setBookingStatusFilter(event.target.value);
    }

    const loadRequests = async () => {
        console.log(user)
        try {
            const params =
                user.userType === 1 ? {} :
                    user.userType === 3 ? { id_secondo_medico: user.userId! } :
                        {};

            const response = await getRequests(params);

            setRequests(response);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: "error" });
        }
    }

    useEffect(() => {
        if (!user.userId || !user.userType || ![1, 3].includes(user.userType)) {
            navigate('/error', { state: { errorStatus: 401 } });
        }

        openLoadingDialog();
        Promise.all([
            loadRequests()
        ]).finally(() => {
            closeLoadingDialog();
        });
    }, []);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, booking: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedRequest(booking);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRequest(null);
    };

    const handleRequestDetails = () => {
        handleMenuClose();
        navigate(`/requests/details`, { state: { requestId: selectedRequest.id_richiesta, patientId: selectedRequest.id_paziente } });
    }

    const handleSendMessage = () => {
        handleMenuClose();
        sendMessage({
            id_richiesta: selectedRequest.id_richiesta,
            id_medico: selectedRequest.id_secondo_medico,
            id_paziente: selectedRequest.id_paziente,
            telefono: selectedRequest.telefono
        }).then(() => {
            enqueueSnackbar('Messaggio inviato con successo', { variant: 'success' });
        }).catch((error: any) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        });
    }

    const handleCopyLink = () => {
        handleMenuClose();

        if (!selectedRequest.id_secondo_medico || selectedRequest.id_secondo_medico === 0) {
            enqueueSnackbar('Non è ancora stato assegnato un medico per la richiesta', { variant: 'error' });
            return;
        }

        createBookingToken({
            id_richiesta: selectedRequest.id_richiesta,
            id_medico: selectedRequest.id_secondo_medico,
            id_paziente: selectedRequest.id_paziente
        }).then((response) => {
            navigator.clipboard.writeText(response.url);
            enqueueSnackbar('Link copiato negli appunti', { variant: 'success' });
        }).catch((error: any) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        });
    }

    const columns: HeadCell<any>[] = user.userType === 1 ? [
        { id: 'id_richiesta', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'paziente', numeric: false, disablePadding: false, label: 'Paziente' },
        { id: 'codice_fiscale', numeric: false, disablePadding: false, label: 'Codice Fiscale' },
        { id: 'data_nascita', numeric: false, disablePadding: false, label: 'Data di nascita' },
        { id: 'stato_richiesta', numeric: false, disablePadding: false, label: 'Stato richiesta' },
        { id: 'prenotazione_esistente', numeric: false, disablePadding: false, label: 'Stato prenotazione' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
    ] : [
        { id: 'id_richiesta', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'paziente', numeric: false, disablePadding: false, label: 'Paziente' },
        { id: 'codice_fiscale', numeric: false, disablePadding: false, label: 'Codice Fiscale' },
        { id: 'data_nascita', numeric: false, disablePadding: false, label: 'Data di nascita' },
        { id: 'stato_richiesta', numeric: false, disablePadding: false, label: 'Stato richiesta' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
    ];

    const renderRow = (request: any) => (
        <TableRow key={request.id}>
            <TableCell align='right'>{request.id_richiesta}</TableCell>
            <TableCell>{request.nome} {request.cognome}</TableCell>
            <TableCell>{request.codice_fiscale}</TableCell>
            <TableCell>{extractDateOfBirthFromItalianFiscalCode(request.codice_fiscale)?.toLocaleDateString('it-IT')}</TableCell>
            <TableCell>
                <Chip
                    label={request.stato_richiesta === 2 ? 'Completata' : request.stato_richiesta === 1 ? 'In lavorazione' : 'Nessuno'}
                    color={request.stato_richiesta === 2 || request.stato_richiesta === 1 ? 'primary' : 'default'}
                    variant={request.stato_richiesta !== 2 ? 'outlined' : 'filled'} />
            </TableCell>
            {user.userType === 1 && (
                <TableCell>
                    {request.prenotazione_esistente === 0 ? (
                        <Chip label="Nessuno" color="default" variant="outlined" />
                    ) : (
                        <>
                            <Chip
                                label={new Date(request.data_disponibilita).toLocaleDateString("it-IT", { year: "numeric", month: "2-digit", day: "2-digit" })}
                                color="secondary"
                                variant="outlined"
                                sx={{ marginRight: ".5rem" }} />
                            <Chip label={`${request.fascia_oraria_inizio}-${request.fascia_oraria_fine}`} color="primary" variant="outlined" />
                        </>
                    )}
                </TableCell>
            )}
            <TableCell>
                <IconButton onClick={(event) => handleMenuOpen(event, request)}>
                    <MaterialIcon icon="more_vert" />
                </IconButton>
            </TableCell>
        </TableRow>
    );

    const filteredList = requests.filter((request) => {
        const searchTerms = searchText.toLowerCase().split(' ');
        const matchesSearch = searchTerms.every(term =>
            (request.nome?.toLowerCase() || '').includes(term) ||
            (request.cognome?.toLowerCase() || '').includes(term) ||
            (request.codice_fiscale?.toLowerCase() || '').includes(term) ||
            (request.id_richiesta?.toString() || '').includes(term)
        );
        const matchesRequestStatus = requestStatusFilter === '' || request.stato_richiesta === Number(requestStatusFilter);
        const matchesBookingStatus = bookingStatusFilter === '' || request.prenotazione_esistente === Number(bookingStatusFilter);
        return matchesSearch && matchesRequestStatus && matchesBookingStatus;
    });

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Richieste</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza le richieste a te assegnate
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <TextField
                    label="Cerca richieste"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
                <FormControl variant="outlined" sx={{ width: "12rem" }}>
                    <InputLabel id="status-filter-label">Stato richiesta</InputLabel>
                    <Select
                        labelId="status-filter-label"
                        id="status-filter"
                        value={requestStatusFilter}
                        onChange={handleRequestStatusFilterChange}
                        label="Stato richiesta"
                    >
                        <MenuItem value={''}>
                            <em>Tutti</em>
                        </MenuItem>
                        <MenuItem value={2}>Completata</MenuItem>
                        <MenuItem value={1}>In lavorazione</MenuItem>
                        <MenuItem value={0}>Nessuno</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ width: "12rem" }}>
                    <InputLabel id="booking-filter-label">Stato prenotazione</InputLabel>
                    <Select
                        labelId="booking-filter-label"
                        id="booking-filter"
                        value={bookingStatusFilter}
                        onChange={handleBookingStatusFilterChange}
                        label="Stato prenotazione"
                    >
                        <MenuItem value={''}>
                            <em>Tutti</em>
                        </MenuItem>
                        <MenuItem value={1}>Prenotato</MenuItem>
                        <MenuItem value={0}>Nessuna prenotazione</MenuItem>
                    </Select>
                </FormControl>
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    rows={filteredList}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleRequestDetails}>
                    <ListItemIcon>
                        <MaterialIcon icon="troubleshoot" />
                    </ListItemIcon>
                    <ListItemText>Analizza richiesta</ListItemText>
                </MenuItem>
                {user.userType === 1 && selectedRequest?.stato_richiesta === 0 && (
                    <>
                        <MenuItem onClick={handleSendMessage}>
                            <ListItemIcon>
                                <MaterialIcon icon="send" />
                            </ListItemIcon>
                            <ListItemText>Invia link alla televisita</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleCopyLink}>
                            <ListItemIcon>
                                <MaterialIcon icon="content_copy" />
                            </ListItemIcon>
                            <ListItemText>Copia link alla televisita</ListItemText>
                        </MenuItem>
                    </>
                )}
            </Menu>
        </Box>
    );
};

export default RequestsList;
