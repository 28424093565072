import apiClient from "./base";

interface Report {
    id_richiesta?: number,
    note_aggiuntive?: string,
    idoneo?: 1 | 0,
}

export const generateReport = async (params: Report) => {
    const token = localStorage.getItem('token');

    const response = await apiClient.post('/reports/generateReport', params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    return response.data;
}