import axios from "axios";
import apiClient from "./base"

interface Request {
    id_richiesta?: number;
    id_utente_richiedente?: number;
    id_paziente?: number;
    id_secondo_medico?: number | null;
}

export const getRequests = async (params: Request) => {
    const token = localStorage.getItem("token");

    const response = await apiClient.get("/requests/getRequests", {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    })

    return response.data;
}

interface ObjectiveExam {
    id_richiesta?: number;
}

export const getObjectiveExamination = async (params: ObjectiveExam) => {
    const token = localStorage.getItem("token");

    const response = await apiClient.get('/requests/getObjectiveExamination', {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    })

    return response.data;
}

export const getRequestDocument = async (requestId: number) => {
    try {
        const response = await axios.get('https://cardio-api.prod.smeditaly.it/api/request/get-request-document/' + requestId, {
            responseType: 'arraybuffer'
        });

        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}