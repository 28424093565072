import React, { useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material';
import CallingListLayout from '../../../components/CallingListLayout';
import RequestDetailsLayout from '../../../components/RequestDetailsLayout';

const Dashboard: React.FC = () => {
    const theme = useTheme();
    const [selectedRequest, setSelectedRequest] = useState<{ requestId: number, patientId: number } | null>(null);
    const [reload, setReload] = useState(false); // Aggiunto per forzare il ricaricamento delle chiamate

    const handleItemClick = (requestId: number, patientId: number) => {
        setSelectedRequest({ requestId, patientId });
    }

    const handleCloseDetails = () => {
        setSelectedRequest(null);
        setReload(!reload); // Cambia lo stato per ricaricare le chiamate
    }

    const handleReloadCalls = () => {
        setReload(!reload);
    }

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Grid container columnSpacing="2rem">
                <Grid item xs={4}>
                    <CallingListLayout onItemClick={handleItemClick} onReject={handleCloseDetails} onReload={handleReloadCalls} />
                </Grid>
                <Grid item xs={8}>
                    <RequestDetailsLayout requestId={selectedRequest?.requestId} patientId={selectedRequest?.patientId} onClose={handleCloseDetails} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
