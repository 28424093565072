import { createBrowserRouter } from "react-router-dom";
import App from "../pages/app/App";
import Login from "../pages/auth/login/Login";
import Dashboard from "../pages/app/dashboard/Dashboard";
import Logout from "../pages/auth/logout/Logout";
import Error from "../pages/error/Error";
import Book from "../pages/book/Book";
import QuestionnaireManagement from "../pages/app/questionnaire-management/QuestionnaireManagement";
import RequestDetails from "../pages/app/requests/details/RequestDetails";
import ValidateOTP from "../pages/auth/validate-otp/ValidateOTP";
import RequestsList from "../pages/app/requests/list/RequestsList";
import AvailabilitiesManagement from "../pages/app/availabilities-management/AvailabilitiesManagement";
import Main from "../pages/Main";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        children: [
            {
                path: "/",
                element: <App />,
                children: [
                    {
                        index: true,
                        path: "dashboard",
                        element: <Dashboard />,
                    },
                    {
                        path: "requests",
                        children: [
                            {
                                index: true,
                                element: <RequestsList />
                            },
                            {
                                path: "details",
                                element: <RequestDetails />
                            }
                        ]
                    },
                    {
                        path: "availabilities",
                        element: <AvailabilitiesManagement />
                    },
                    {
                        path: "questionnaire",
                        element: <QuestionnaireManagement />
                    },
                ]
            },
            {
                path: "prenota/:token",
                element: <Book />
            },
            {
                path: "login",
                children: [
                    {
                        path: "step1",
                        element: <Login />
                    },
                    {
                        path: "step2",
                        element: <ValidateOTP />
                    }
                ]
            },
            {
                path: "logout",
                element: <Logout />
            },
            {
                path: "error",
                element: <Error />
            },
            {
                path: "*",
                element: null
            },
        ]
    },
]);

export default router;