import { Box, Card, Divider, IconButton, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useUserProvider } from "../providers/useUserProvider";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../providers/useSwallowLoading";
import { useEffect, useState } from "react";
import { getBookings, rejectBooking } from "../api/bookings";
import MaterialIcon from "./MaterialIcon";
import { useNavigate } from "react-router-dom";

interface Props {
    onItemClick: (requestId: number, patientId: number) => void,
    onReject: () => void,
    onReload: () => void // Aggiunto per ricaricare le chiamate
}

const CallingListLayout: React.FC<Props> = (props: Props) => {
    const { user } = useUserProvider();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const navigate = useNavigate();

    const [tabValue, setTabValue] = useState(0);
    const [waitingCalls, setWaitingCalls] = useState<any[]>([]);
    const [rejectedCalls, setRejectedCalls] = useState<any[]>([]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const loadCallsHistory = async () => {
        try {
            const response = await getBookings({
                id_medico: user.userId!,
                data_prenotazione: new Date().toISOString().split('T')[0]
            });

            setWaitingCalls(response.filter((call: any) => call.timestamp_rifiuto === null && call.stato_richiesta === 0));
            setRejectedCalls(response.filter((call: any) => call.timestamp_rifiuto !== null && call.stato_richiesta === 0));
        } catch (error) {
            enqueueSnackbar('Errore durante il caricamento delle chiamate', { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    const handleItemClick = (requestId: number, patientId: number) => {
        if (props.onItemClick) {
            props.onItemClick(requestId, patientId);
        }
    }

    const handleCall = (phoneNumber: string) => {
        const trimmed = phoneNumber.replace(/\s/g, '');
        const url = `https://wa.me/${trimmed}`;
        window.open(url, '_blank', 'noopener noreferrer');
    }

    const handleReject = async (bookingId: number) => {
        try {
            await rejectBooking({ id_prenotazione: bookingId });
            enqueueSnackbar('Richiesta rifiutata', { variant: 'success' });

            // Aggiorna gli stati locali
            const updatedWaitingCalls = waitingCalls.filter(call => call.id_prenotazione !== bookingId);
            const rejectedCall = waitingCalls.find(call => call.id_prenotazione === bookingId);
            setWaitingCalls(updatedWaitingCalls);
            if (rejectedCall) {
                setRejectedCalls([...rejectedCalls, rejectedCall]);
            }

            if (props.onReject) {
                props.onReject();
            }
        } catch (error) {
            enqueueSnackbar('Errore durante il rifiuto della richiesta', { variant: 'error' });
        }
    }

    useEffect(() => {
        if (!user) {
            return;
        }

        openLoadingDialog();
        loadCallsHistory();
    }, [user]);

    useEffect(() => {
        props.onReload(); // Aggiunto per ricaricare le chiamate quando cambia la scheda
    }, [tabValue]);

    const list = (tabValue === 0 ? waitingCalls : rejectedCalls);

    return (
        <Box>
            <Typography variant="h4" marginBottom="1rem" color={theme.palette.textDark.primary} fontWeight="bold">Chiamate</Typography>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Tabs"
                    variant='fullWidth'>
                    <Tab label={`In attesa (${waitingCalls ? waitingCalls.length : 0})`} />
                    <Tab label={`Rifiutate (${rejectedCalls ? rejectedCalls.length : 0})`} />
                </Tabs>
                <Divider />
                <List>
                    {list.length === 0 && (
                        <ListItem>
                            <ListItemText primary={tabValue === 0 ? "Nessuna chiamata in attesa" : "Nessuna chiamata rifiutata"} />
                        </ListItem>
                    )}
                    {list.length > 0 && list.map((call) => (
                        <ListItemButton key={call.id_prenotazione} onClick={() => handleItemClick(call.id_richiesta, call.id_paziente)}>
                            <ListItemText primary={`${call.nome_paziente} ${call.cognome_paziente}`} secondary={`${call.fascia_oraria_inizio} - ${call.fascia_oraria_fine}`} />
                            <ListItemSecondaryAction>
                                <IconButton sx={{ marginRight: '0.5rem' }} onClick={() => handleCall(call.telefono_paziente)}>
                                    <MaterialIcon icon="phone" />
                                </IconButton>
                                {tabValue === 0 && (
                                    <IconButton onClick={() => handleReject(call.id_prenotazione)}>
                                        <MaterialIcon icon="do_not_disturb_on" />
                                    </IconButton>
                                )}
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    ))}
                </List>
            </Card>
        </Box>
    );
};

export default CallingListLayout;
