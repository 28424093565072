import apiClient from "./base";

interface User {
    id_utenti?: number;
    tipi_utenti_id_tipi_utenti?: number;
    codice_fiscale?: string;
}

export const getUsers = async (params: User) => {
    const token = localStorage.getItem("token");

    const response = await apiClient.get("/users/getUsers", {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    })

    return response.data;
}

interface MedicalHistory {
    id_anamnesi?: number;
    id_utente?: number;
}

export const getMedicalHistory = async (params: MedicalHistory) => {
    const token = localStorage.getItem("token");

    const response = await apiClient.get("/users/getMedicalHistory", {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    })

    return response.data;
}