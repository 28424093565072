import React, { useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RequestDetailsLayout from '../../../../components/RequestDetailsLayout';
import { useUserProvider } from '../../../../providers/useUserProvider';

const RequestDetails: React.FC = () => {
    const { user } = useUserProvider();
    const { state } = useLocation();
    const { requestId, patientId } = state || { requestId: null, patientId: null };
    const navigate = useNavigate();

    const handleCloseDetails = () => {
        navigate('/requests');
    }

    useEffect(() => {
        if (!user.userType || ![1, 3].includes(user.userType)) {
            navigate('/error', { state: { errorStatus: 401 } });
        }

        if (!requestId || !patientId) {
            navigate('/requests');
        }
    }, [requestId, patientId]);

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <RequestDetailsLayout requestId={requestId} patientId={patientId} onClose={handleCloseDetails} />
        </Box>
    );
};

export default RequestDetails;
