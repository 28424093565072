export function extractDateOfBirthFromItalianFiscalCode(fiscalCode: string): Date | null {
    if (fiscalCode.length !== 16) {
        console.error("Il codice fiscale deve essere lungo 16 caratteri.");
        return null;
    }

    const yearPart = fiscalCode.substr(6, 2);
    const monthPart = fiscalCode.charAt(8);
    const dayPart = parseInt(fiscalCode.substr(9, 2), 10);

    const monthMap: { [key: string]: number } = {
        A: 1, B: 2, C: 3, D: 4, E: 5, H: 6, L: 7, M: 8, P: 9, R: 10, S: 11, T: 12
    };

    const month = monthMap[monthPart];
    if (month === undefined) {
        console.error("Mese non valido nel codice fiscale.");
        return null;
    }

    let currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // +1 perché i mesi sono indicizzati da 0 a 11
    if (currentMonth < month) {
        currentYear -= 1; // Riduci l'anno corrente se il mese attuale è inferiore al mese estratto
    }

    let year = parseInt(yearPart, 10);
    year += year < 100 ? (year > currentYear % 100 ? 1900 : 2000) : 0;

    const day = dayPart > 40 ? dayPart - 40 : dayPart;

    const dateOfBirth = new Date(year, month - 1, day); // -1 perché i mesi sono indicizzati da 0 a 11

    if (isNaN(dateOfBirth.getTime())) {
        console.error("Data di nascita non valida.");
        return null;
    }

    return dateOfBirth;
}

export enum MedicalHistoryType {
    General = 'Anamnesi Generale',
    Family = 'Anamnesi Familiare',
    Physiological = 'Anamnesi Fisiologica',
    Pathological = 'Anamnesi Patologica',
    OtherPathological = 'Altri tipi di patologie',
    More = 'Ulteriori annotazioni',
}

export const medicalHistoryKeys = {
    altezza: { name: 'Altezza (cm)', type: MedicalHistoryType.General },
    peso: { name: 'Peso (cm)', type: MedicalHistoryType.General },
    malattie_cardiache: { name: 'Malattie Cardiache', type: MedicalHistoryType.Family },
    ipertensione: { name: 'Ipertensione', type: MedicalHistoryType.Family },
    infarto_del_miocardio: { name: 'Infarto Del Miocardio', type: MedicalHistoryType.Family },
    diabete_mellito: { name: 'Diabete Mellito', type: MedicalHistoryType.Family },
    colesterolo_alto: { name: 'Colesterolo Alto', type: MedicalHistoryType.Family },
    malattie_polmonari: { name: 'Malattie Polmonari', type: MedicalHistoryType.Family },
    tumori: { name: 'Tumori', type: MedicalHistoryType.Family },
    sordita: { name: 'Sordita', type: MedicalHistoryType.Family },
    morte_improvvisa: { name: 'Morte Improvvisa', type: MedicalHistoryType.Family },
    nato_da_parto_naturale: { name: 'E\' nato da parto naturale o a termine', type: MedicalHistoryType.Physiological },
    alcol: { name: 'Alcol', type: MedicalHistoryType.Physiological },
    allattato_seno: { name: 'Allattato Seno', type: MedicalHistoryType.Physiological },
    assume_farmaci: { name: 'Assume farmaci e/o integratori', type: MedicalHistoryType.Physiological },
    fumi: { name: 'Fumi', type: MedicalHistoryType.Physiological },
    allergie: { name: 'Allergie', type: MedicalHistoryType.Pathological },
    anemia: { name: 'Anemia', type: MedicalHistoryType.Pathological },
    palpitazioni: { name: 'Palpitazioni', type: MedicalHistoryType.Pathological },
    pressione_arteriosa_alta: { name: 'Pressione Arteriosa Alta', type: MedicalHistoryType.Pathological },
    malattie_fegato_tratto_biliare: { name: 'Malattie Fegato Tratto Biliare', type: MedicalHistoryType.Pathological },
    malattie_endocrine: { name: 'Malattie Endocrine', type: MedicalHistoryType.Pathological },
    asma_bronchiale: { name: 'Asma Bronchiale', type: MedicalHistoryType.Pathological },
    epilessia: { name: 'Epilessia', type: MedicalHistoryType.Pathological },
    dolore_toracico: { name: 'Dolore Toracico', type: MedicalHistoryType.Pathological },
    cafalee_ricorrenti: { name: 'Cafalee Ricorrenti', type: MedicalHistoryType.Pathological },
    neoplasie: { name: 'Neoplasie', type: MedicalHistoryType.Pathological },
    mal_sistema_muscolo_scheletrico: { name: 'Mal Sistema Muscolo Scheletrico', type: MedicalHistoryType.Pathological },
    disturbi_apparato_respiratorio: { name: 'Disturbi Apparato Respiratorio', type: MedicalHistoryType.Pathological },
    svenimenti: { name: 'Svenimenti', type: MedicalHistoryType.Pathological },
    vertigini: { name: 'Vertigini', type: MedicalHistoryType.Pathological },
    disturbi_visivi: { name: 'Disturbi Visivi', type: MedicalHistoryType.Pathological },
    malattie_neurologiche: { name: 'Malattie Neurologiche', type: MedicalHistoryType.Pathological },
    malattie_psichiatriche: { name: 'Malattie Psichiatriche', type: MedicalHistoryType.Pathological },
    otiti_sinusiti: { name: 'Otiti / Sinusiti', type: MedicalHistoryType.Pathological },
    malattie_cuore: { name: 'Malattie Cuore', type: MedicalHistoryType.Pathological },
    acufeni: { name: 'Acufeni', type: MedicalHistoryType.Pathological },
    malattie_gastrointestinali: { name: 'Malattie Gastrointestinali', type: MedicalHistoryType.Pathological },
    mal_reni_tratto_urinario: { name: 'Mal Reni / Tratto Urinario', type: MedicalHistoryType.Pathological },
    interventi_chirurgici: { name: 'Interventi Chirurgici', type: MedicalHistoryType.Pathological },
    fratture_distorsioni_gravi: { name: 'Fratture Distorsioni Gravi', type: MedicalHistoryType.Pathological },
    gravidanza_in_corso: { name: 'Gravidanza In Corso', type: MedicalHistoryType.More },
    sport: { name: 'Sport', type: MedicalHistoryType.More },
    anomalie_ciclo: { name: 'Anomalie Ciclo', type: MedicalHistoryType.More },
    lavora: { name: 'Lavora', type: MedicalHistoryType.More },
    usa_occhiali: { name: 'Usa Occhiali o lenti a contatto', type: MedicalHistoryType.OtherPathological },
    dispositivi_acustici: { name: 'Usa dispositivi acustici', type: MedicalHistoryType.OtherPathological },
    plantari_ausili_odontoiatrici: { name: 'Usa plantari o ausili odontoiatrici', type: MedicalHistoryType.OtherPathological },
    text_altro: { name: 'Altro', type: MedicalHistoryType.More },
};