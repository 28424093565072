import React, { useState, useEffect } from 'react';
import { Box, Card, Typography, Stepper, Step, StepLabel, Button, FormControl, Select, MenuItem, SelectChangeEvent, InputLabel, Checkbox, FormControlLabel } from '@mui/material';
import { useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useSwallowLoading } from '../../providers/useSwallowLoading';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { createBooking, decodeBookingToken, getAvailableDates, getBookings } from '../../api/bookings';
import { EventAttributes, createEvent } from 'ics';
import Logo from '../../components/Logo';
import { getRequests } from '../../api/requests';

const Book: React.FC = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const { token } = useParams<{ token: string }>();
    localStorage.setItem('token', token?.toString() || '');
    const [tokenData, setTokenData] = useState<any>({});
    const [dates, setDates] = useState<any[]>([]);
    const [isAlreadyBooked, setIsAlreadyBooked] = useState(false);
    const [isBookingCompleted, setIsBookingCompleted] = useState(false);
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);

    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [selectedTime, setSelectedTime] = useState<string | null>(null);
    const [selectedIdDisponibilita, setSelectedIdDisponibilita] = useState<number | null>(null);
    const [addReminder, setAddReminder] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const steps = ['Seleziona Data', 'Seleziona Orario', 'Conferma'];

    const submit = async () => {
        if (!token) {
            enqueueSnackbar('Errore durante la prenotazione', { variant: 'error' });
            return;
        }

        if (!selectedDate || !selectedTime || !selectedIdDisponibilita) {
            enqueueSnackbar('Seleziona una data e un orario', { variant: 'error' });
            return;
        }

        const [fascia_oraria_inizio, fascia_oraria_fine] = selectedTime.split('-');

        try {
            openLoadingDialog();
            await createBooking({
                id_richiesta: tokenData.id_richiesta!,
                id_disponibilita: selectedIdDisponibilita,
                fascia_oraria_inizio,
                fascia_oraria_fine
            });
            closeLoadingDialog();
            setIsBookingCompleted(true);
            enqueueSnackbar('Prenotazione effettuata con successo', { variant: 'success' });

            if (addReminder) {
                addEventToCalendar(selectedDate, fascia_oraria_inizio, fascia_oraria_fine);
            }
        } catch (error) {
            closeLoadingDialog();
            enqueueSnackbar('Errore durante la prenotazione', { variant: 'error' });
        }
    }

    useEffect(() => {
        if (!token) {
            enqueueSnackbar('Errore durante il caricamento della prenotazione', { variant: 'error' });
            return;
        }

        openLoadingDialog();
        decodeBookingToken(token).then((response) => {
            const { id_medico, id_paziente, id_richiesta } = response;
            setTokenData(response);

            Promise.all([
                getRequests({ id_richiesta }),
                getBookings({ id_richiesta, rifiutato: false, cancellato: false }),
                getAvailableDates({ id_medico })
            ])
                .then(([requests, bookings, availableDates]) => {
                    if (requests.length === 0) {
                        enqueueSnackbar('Richiesta non trovata', { variant: 'error' });
                        return;
                    }

                    const request = requests[0];

                    if (request.stato_richiesta !== 0) {
                        setIsRequestCompleted(true);
                        enqueueSnackbar('Richiesta già completata', { variant: 'info' });
                    }

                    if (bookings.filter((booking: any) => booking.timestamp_rifiuto === null && booking.timestamp_cancellazione === null).length > 0) {
                        setIsAlreadyBooked(true);
                        enqueueSnackbar('Prenotazione già effettuata', { variant: 'info' });
                    }

                    setDates(availableDates);
                    setIsPageLoading(false);
                })
                .catch((error: AxiosError) => {
                    enqueueSnackbar('Errore durante il caricamento della prenotazione', { variant: 'error' });
                })
                .finally(() => {
                    closeLoadingDialog();
                });
        }).catch((error) => {
            enqueueSnackbar('Errore durante il caricamento della prenotazione', { variant: 'error' });
        }).finally(() => {
            closeLoadingDialog();
        });
    }, []);

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            submit();
            return;
        }

        if (activeStep === 0 && !selectedDate) {
            enqueueSnackbar('Seleziona una data', { variant: 'error' });
            return;
        }

        if (activeStep === 1 && !selectedTime) {
            enqueueSnackbar('Seleziona un orario', { variant: 'error' });
            return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleDateSelect = (event: SelectChangeEvent) => {
        const selectedDate = event.target.value as string;
        setSelectedDate(selectedDate);
        setSelectedIdDisponibilita(dates.find(date => date.data_disponibilita === selectedDate)?.id_disponibilita || null);
        setSelectedTime(null);
    }

    const handleTimeSelect = (event: SelectChangeEvent) => {
        setSelectedTime(event.target.value as string);
    };

    const addEventToCalendar = (date: string, startTime: string, endTime: string) => {
        const [startHour, startMinute] = startTime.split(':');
        const [endHour, endMinute] = endTime.split(':');

        const event: EventAttributes = {
            start: [parseInt(dayjs(date).format('YYYY')), parseInt(dayjs(date).format('MM')), parseInt(dayjs(date).format('DD')), parseInt(startHour), parseInt(startMinute)],
            end: [parseInt(dayjs(date).format('YYYY')), parseInt(dayjs(date).format('MM')), parseInt(dayjs(date).format('DD')), parseInt(endHour), parseInt(endMinute)],
            title: 'Prenotazione Televisita',
            description: 'Promemoria per la tua prenotazione della televisita con SMED.',
            location: 'Online',
            url: 'http://smed.com',
            organizer: { name: 'SMED', email: 'support@smed.com' },
        };

        createEvent(event, (error, value) => {
            if (error) {
                console.error(error);
                return;
            }
            const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'promemoria_televisita.ics';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    const renderDateSelection = () => (
        <FormControl component="fieldset" fullWidth>
            <InputLabel htmlFor="date">Data</InputLabel>
            <Select
                id="date"
                label="Data"
                value={selectedDate ?? ''}
                onChange={handleDateSelect}
                fullWidth
            >
                {dates.length === 0 && <MenuItem value="" disabled>Nessuna data disponibile</MenuItem>}
                {dates.map((date) => (
                    <MenuItem key={date.id_disponibilita} value={date.data_disponibilita}>
                        {dayjs(date.data_disponibilita).format('DD MMMM YYYY')}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const renderTimeSelection = () => (
        <FormControl component="fieldset" fullWidth>
            <InputLabel htmlFor="time">Orario</InputLabel>
            <Select
                id='time'
                label="Orario"
                value={selectedTime ?? ''}
                onChange={handleTimeSelect}
                fullWidth
            >
                {selectedDate === null && <MenuItem value="" disabled>Seleziona una data</MenuItem>}
                {dates.find((d) => d.data_disponibilita === selectedDate)?.availableIntervals.map((time: any, index: number, array: any[]) => {
                    const ct = time.substring(0, 5);
                    const nt = dayjs(time, 'HH:mm').add(15, 'minute').format('HH:mm');

                    return (
                        <MenuItem key={time} value={`${ct}-${nt}`}>
                            {`${ct}-${nt}`}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );

    const renderConfirmation = () => (
        <Box>
            <Typography variant="h6" gutterBottom color={theme.palette.text.primary}>
                Conferma la tua prenotazione
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary}>
                Data: {dayjs(selectedDate).format('DD MMM YYYY')}
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary}>
                Orario: {selectedTime}
            </Typography>
            <Typography variant='body2' marginTop="1rem" marginBottom="1rem" color={theme.palette.text.secondary}>
                *In caso di pazienti minori, è necessario che sia presente un genitore o un tutore legale durante la visita.
            </Typography>
            <FormControlLabel
                control={<Checkbox checked={addReminder} onChange={(e) => setAddReminder(e.target.checked)} color="primary" />}
                label="Aggiungi un promemoria al mio calendario"
                sx={{ display: 'none' }}
            />
        </Box>
    );

    if (isPageLoading) {
        return <Box padding="2rem" boxSizing='border-box'></Box>;
    }

    if (isRequestCompleted) {
        return (
            <Box padding="2rem" boxSizing='border-box'>
                <Box display="flex" justifyContent="center" height="3rem" marginBottom="2rem">
                    <Logo primaryColor='#ffffff' />
                </Box>
                <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                    Richiesta completata
                </Typography>
                <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.secondary}>
                    La tua richiesta è stata completata con successo.
                    <br />
                    Grazie per aver utilizzato i servizi SMED.
                </Typography>
            </Box>
        );
    }

    if (isAlreadyBooked) {
        return (
            <Box padding="2rem" boxSizing='border-box'>
                <Box display="flex" justifyContent="center" height="3rem" marginBottom="2rem">
                    <Logo primaryColor='#ffffff' />
                </Box>
                <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                    Prenotazione già effettuata
                </Typography>
                <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.secondary}>
                    Hai già prenotato una visita. Se hai bisogno di assistenza, contatta il nostro supporto.
                </Typography>
            </Box>
        );
    }

    if (isBookingCompleted) {
        return (
            <Box padding="2rem" boxSizing='border-box'>
                <Box display="flex" justifyContent="center" height="3rem" marginBottom="2rem">
                    <Logo primaryColor='#ffffff' />
                </Box>
                <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                    Prenotazione completata
                </Typography>
                <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.secondary}>
                    La tua prenotazione è stata completata con successo.
                    <br />
                    Grazier per aver utilizzato i servizi SMED.
                </Typography>
            </Box>
        );
    }

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Box display="flex" justifyContent="center" height="3rem" marginBottom="2rem">
                <Logo primaryColor='#ffffff' />
            </Box>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                Prenota la tua Televisita
            </Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.secondary}>
                Benvenuto e grazie per aver scelto di utilizzare i Servizi SMED.<br />Per prenotare la tua visita, seleziona una data e un orario disponibile.
            </Typography>
            <Card sx={{ borderRadius: "1rem", padding: "2rem" }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box marginTop="2rem">
                    {activeStep === 0 && renderDateSelection()}
                    {activeStep === 1 && renderTimeSelection()}
                    {activeStep === 2 && renderConfirmation()}
                </Box>
                <Box marginTop="2rem" display="flex" justifyContent="space-between">
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="text"
                    >
                        Indietro
                    </Button>
                    <Button
                        disabled={activeStep === 0 && !selectedDate}
                        onClick={handleNext}
                        variant="contained"
                        color="primary"
                    >
                        {activeStep === steps.length - 1 ? 'Conferma' : 'Avanti'}
                    </Button>
                </Box>
            </Card>
        </Box>
    );
};

export default Book;
